import { template as template_809e92c80805435fb2a26d9b67b87de8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_809e92c80805435fb2a26d9b67b87de8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
