import { template as template_67968b6f2327449f9b4233e683d8b7f2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_67968b6f2327449f9b4233e683d8b7f2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
